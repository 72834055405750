import Layout from "../components/layout"
import React from "react"
import {
  Title,
  ContactContent,
  ContactWrapper,
  Paragraph1,
} from "../styles/contact/style"
import { Link as GLink } from "gatsby"
import styled from "styled-components"

const Link = styled(GLink)`
  color: white;
`

const NotFound = () => {
  return (
    <Layout>
      <ContactWrapper>
        <ContactContent>
          <Title>Page not found</Title>
          <Paragraph1>
            Oops! The page you are looking for has been removed or relocated.
          </Paragraph1>
          <Paragraph1>
            <Link to="/">Go back</Link>
          </Paragraph1>
        </ContactContent>
      </ContactWrapper>
    </Layout>
  )
}

export default NotFound
