import styled from "styled-components"

const ContactWrapper = styled.div`
  background-color: ${({
    theme: {
      palette: { darkblue },
    },
  }) => darkblue};
  padding-top: ${({
    theme: {
      header: { mobile },
    },
  }) => mobile};
  min-height: calc(
    100vh -
      ${({
        theme: {
          footer: { mobile },
        },
      }) => mobile}
  );
  color: white;

  @media only screen and (min-width: 576px) {
    padding-top: ${({
      theme: {
        header: { desktop },
      },
    }) => desktop};
    min-height: calc(
      100vh -
        ${({
          theme: {
            footer: { desktop },
          },
        }) => desktop}
    );
  }
`

const ContactContent = styled.div`
  padding: ${({
    theme: {
      content: {
        padding: { mobile },
      },
    },
  }) => mobile};
  padding-bottom: ${({
    theme: {
      footer: { mobile },
    },
  }) => mobile};
  padding-top: 6rem;

  @media only screen and (min-width: 576px) and (max-width: 767px) {
    padding: ${({
      theme: {
        content: {
          padding: { tablet },
        },
      },
    }) => tablet};
    padding-bottom: ${({
      theme: {
        footer: { mobile },
      },
    }) => mobile};
  }

  @media only screen and (min-width: 768px) {
    padding: ${({
      theme: {
        content: {
          padding: { tablet },
        },
      },
    }) => tablet};
    padding-top: 10rem;
    padding-bottom: ${({
      theme: {
        footer: { mobile },
      },
    }) => mobile};
  }

  @media only screen and (min-width: 1900px) {
    padding: ${({
      theme: {
        content: {
          padding: { desktop },
        },
      },
    }) => desktop};
    padding-top: 10rem;
    padding-bottom: ${({
      theme: {
        footer: { mobile },
      },
    }) => mobile};
  }
`

const Title = styled.h1`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s108};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w100};
`

const Paragraph1 = styled.p`
  margin: ${({ theme: { spacing } }) => spacing.s40} 0;
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s42};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w100};
  line-height: 3.5rem;
`

const Part2 = styled.span`
  display: block;
  margin-top: ${({ theme: { spacing } }) => spacing.s15};

  @media only screen and (min-width: 576px) {
    display: inline;
  }
`

const Paragraph2 = styled.p`
  margin: ${({ theme: { spacing } }) => spacing.s40} 0;
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s28};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w100};
  line-height: ${({ theme: { spacing } }) => spacing.s50};
`

export { Paragraph2, Part2, Paragraph1, Title, ContactContent, ContactWrapper }
